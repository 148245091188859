/*
Template Name: Admin Template
Author: Maskoid Technologies
File: scss
*/

@import '@mycentyr/centyr-ui/src/assets/scss/variables.scss';

// Import Bootstrap source files (Required file)
@import '~bootstrap/scss/bootstrap.scss';

//Import HopCMS Bootstrap Overides
@import '@mycentyr/centyr-ui/src/assets/scss/custom.scss';

//Import quill(text editor) css
@import 'react-quill/dist/quill.snow.css';